import React from 'react'
import { config, useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { Link } from 'gatsby'

const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  padding-top: 0.1em;
`

const FAQ = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout color="#D9FFDF">
      <SEO title="FAQ | Ink By Kiki" desc="Kiki's Tattoo Service | Ink By Kiki" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 10]}>
        <animated.h1 style={titleAnimation}>FAQ</animated.h1>
        <Description style={descAnimation}>
          <h4>How should I prepare for my tattoo session?</h4>
          <p>It’s not a bad idea to have something to eat prior to your tattoo session, especially if you’re prone to feeling light headed. Make sure you're hydrated, and feel free to bring water. If you’re in for a long session, it wouldn’t hurt to bring a snack! I do have candy to offer clients! Come well rested and wear loose, comfortable clothing that allows for easy access to the area to be tattooed. Do not drink alcohol or take medication (including Advil, tylenol) that thins blood 24 hours before your tattoo session.</p>

          <h4 style={{paddingTop: "0.5em"}}>Can I bring a friend to my appointment?</h4>
          <p>Normally you'd be more than welcome to bring one friend, however due to Covid-19 restrictions I ask that you come alone to your appointment. If you have serious concerns about this please reach out to me! </p>

          <h4 style={{paddingTop: "0.5em"}}>How do I take care of my tattoo?</h4>
            <p>See <Link style={{textDecoration: 'none'}} to="/" aria-label="ink.by.kiki, Back to Home">
             Aftercare 
          </Link>.</p>

          <h4 style={{paddingTop: "0.5em"}}>How long will it take until my tattoo is healed?</h4>
          <p>Everyone is different but as far as general guidelines go: your tattoo will look healed after approximately 2-3 weeks. Complete healing of deeper skin layers can take up to ~4 months. </p>

          <h4 style={{paddingTop: "0.5em"}}>My tattoo is itchy and peeling. Is this normal?</h4>
          <p>Yes! This is completely normal, and it is very important <b>NOT</b> to mess with your tattoo at this time. Scabs will fall off when they’re ready. Do what you can to minimize clothing from rubbing against your tattoo. Pulling off scabs prematurely can result in loss of ink. </p>

          <h4 style={{paddingTop: "0.5em"}}>How often should I apply lotion/ointment to my tattoo?</h4>
          <p>Apply lotion/ointment after you wash your tattoo, so 2~3 times a day is sufficient. When applying, apply a light layer! Applying too much can also potentially draw out ink. Your tattoo needs to breathe, so smothering on a thick layer will do you more harm than good. Tap the area lightly to allow the lotion/ointment to absorb rather than aggressively rubbing it in. 
  Suggested products available on Amazon: Hustle Butter, Tattoo Goo.</p>
          <p>See <Link style={{textDecoration: 'none'}} to="/" aria-label="ink.by.kiki, Back to Home">
             Aftercare 
          </Link> for additional product suggestions.</p>

          <h4 style={{paddingTop: "0.5em"}}>Can I submerge my new tattoo in water?</h4>
          <p>Avoid submerging your new tattoo for the first 2 weeks! Doing so can negatively influence the healing of your tattoo and put you at risk for infection. It’s also a good idea to limit the time your new tattoo is directly in the firing line of your shower head. </p>

          <h4 style={{paddingTop: "0.5em"}}>Can I go out in the sun with my new tattoo?</h4>
          <p>A fresh tattoo is very sensitive to the sun, even minimal exposure can lead to irritation/burns. Avoid direct exposure for the first 2 weeks. </p>

          <h4 style={{paddingTop: "0.5em"}}>What can I do to help along the healing of my tattoo?</h4>
          <p>Doing everything discussed above is a good start. It’s important to remember that a tattoo at the end of the day is an open wound caused by trauma from a tattoo gun. Like any injury, healing will run its course most optimally when you’re living a healthy lifestyle and providing your body with the resources necessary to heal itself thoroughly. This means being mentally and physically well-rested and well-nourished. Stay active, stay hydrated, take your vitamins/mineral supplements, get good sleep, and eat well!

  Along these lines… If you’re sick or suffering from an ongoing medical condition, your immune system will be tied up dealing with other bodily issues and will have less resources to direct towards the healing of your new tattoo. This may result in slower healing of your tattoo. </p>

          <h4 style={{paddingTop: "0.5em"}}>Will I need a touch-up?</h4>
          <p>Even if you’ve done all of the above, you might be unsatisfied with the healing of your tattoo. A free touch-up appointment is available as long as it’s booked within 6 months of the initial appointment.</p>

          <h4 style={{paddingTop: "0.5em"}}>Do you do cover-ups?</h4>
          <p> I take cover-ups on a case by case basis. Feel free to shoot me an email to see if your cover-up is feasible. Please include a photo of the tattoo to be covered. </p>

          <h4 style={{paddingTop: "0.5em"}}>Is your ink vegan?</h4>
          <p>Yes! If this is a serious concern of yours, please let me know.</p>

          <h4 style={{paddingTop: "0.5em"}}>Will I be charged GST?</h4>
          <p>Yes, unfortunately tattooing is a taxable service. </p>

          <h4 style={{paddingTop: "0.5em"}}>Do I have to tip?</h4>
          <p>Tipping is a gesture of appreciation towards the artist. Standard gratuity of 10-20% can apply, but any amount that you'd like to give is much appreciated. But like with any service, tipping is not required.</p>
        </Description>
      </AnimatedBox>
    </Layout>
  )
}

export default FAQ
